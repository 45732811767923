import React from "react";
import "./css/Footer.css";
import footer from "../../Assets/footer.png";
import eventgemLogo from "../../Assets/eventgemLogo.svg";
import { FaInstagram, FaXTwitter } from "react-icons/fa6";
import { TiSocialFacebookCircular, TiMail } from "react-icons/ti";
import { Link, useLocation } from "react-router-dom";

export default function FoorterDetails() {
  const location = useLocation();
  const displayPage =
    location.pathname === "/login" ||
    location.pathname === "/signup" ||
    location.pathname === "/forgotpassword" ||
    location.pathname === "/resetpassword" ||
    location.pathname === "/verifyemail" ||
    location.pathname === "/doormanagement" ||
    location.pathname === "/scanner" ||
    location.pathname === "/dupage" ||
    location.pathname === "/organizersignup" ||
    location.pathname === "/organizerlogin" ||
    location.pathname === "/devicescanner" ||
    location.pathname === "/camerascanner" ||
    location.pathname === "/doormanagerdetails" ||
    location.pathname === "/invalidlink";
  return (
    <>
      {!displayPage && (
        <div className="footer">
          <div className="container">
            <div className="row justify-content-between footer-box">
              <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                <Link to="/">
                  <img
                    src={eventgemLogo}
                    alt=""
                    className="footer-img img-fluid"
                  />
                </Link>
              </div>
              <div className="col-lg-2 col-md-12 col-sm-12 col-12 footer-link">
                <h3>About</h3>
                <a href="">About Us</a>
                <Link to="/privacypolicy">Privacy Policy</Link>
                <Link to="/cookiepolicy">Cookie Policy</Link>
              </div>
              <div className="col-lg-2 col-md-12 col-sm-12 col-12 footer-link">
                <h3>Support</h3>
                <Link to="/faq">FAQ</Link>
                <Link to="/termsandconditions">Terms And Conditions</Link>
              </div>
              <div className="col-lg-2 col-md-12 col-sm-12 col-12 footer-link ">
                <h3>Connect</h3>
                <a
                  href="mailto:info@eventgem.com"
                  className="align-items-center"
                >
                  <TiMail
                    style={{ width: "28px", height: "28px", color: "#024DDF" }}
                  />
                  info@eventgem.com
                </a>
              </div>
              <div className="col-lg-2 col-md-12 col-sm-12 col-12 footer-link">
                <h3>Organizer</h3>

                <Link to="/organizerlogin">Let’s get started!</Link>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 col-12 footer-border py-2"></div>
              <div className="col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-center flex-column">
                <div className="artist-follow-icon mt-5">
                  <Link
                    to="https://www.instagram.com/eventgemtickets?igsh=Ymc2ZTd2NnUyOTJn"
                    target="_blank"
                  >
                    <FaInstagram
                      style={{
                        width: "32px",
                        height: "32px",
                        color: "#024DDF",
                      }}
                    />
                  </Link>
                </div>
                <h4 className="footerarr">
                  © 2024 Eventgem. All rights reserved.
                </h4>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
